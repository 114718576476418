require('./bootstrap');
require('magnific-popup');


// hamburger menu
var hamburger = $('#hamburger');

hamburger.on('click', function(){
    $(this).toggleClass('open');
});


hamburger.on('click', function(){
    $('.main-menu-collapse').toggleClass('show');
});



const initGallery = () => {
    $('#galleryimages').magnificPopup({
        delegate: '.active a', // child items selector, by clicking on it popup will open
        type: 'image',
        gallery: {
            enabled: true
        },
        removalDelay: 500,
        callbacks: {
            beforeOpen: function() {
                // just a hack that adds mfp-anim class to markup
                this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                this.st.mainClass = this.st.el.attr('data-effect');
            }
        },
        closeOnContentClick: true,
        midClick: true
    });
}

initGallery()


$('.gallery-title').on('click', function () {
    $('.gallery-title').each(function () {
        $(this).removeClass('active')
    })
    $(this).addClass('active')
    let galleryId = $(this).data('gallery-id')
    $('#galleryimages>div').each(function () {
        if(galleryId === 0) {
            if($(this).data('gallery-id') !== 2) {
                $(this).removeClass('hide').addClass('active');
            } else {
                $(this).removeClass('hide active')
            }

        } else {
            if($(this).data('gallery-id') !== galleryId) {
                $(this).addClass('hide').removeClass('active');
            } else {
                if($(this).data('gallery-id') !== 2) {

                }
                $(this).removeClass('hide').addClass('active');
            }
        }
    })
})


$(document).ready(function() {
    $('.popup-youtube').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,

        fixedContentPos: false
    });
});



const initGallery2 = () => {
    $('#galleryimagestwo').magnificPopup({
        delegate: 'a', // child items selector, by clicking on it popup will open
        type: 'image',
        gallery: {
            enabled: true
        },
        removalDelay: 500,
        callbacks: {
            beforeOpen: function() {
                // just a hack that adds mfp-anim class to markup
                this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                this.st.mainClass = this.st.el.attr('data-effect');
            }
        },
        closeOnContentClick: true,
        midClick: true
    });
}

initGallery2 ()
